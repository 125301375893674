import React, { useEffect } from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route
} from "react-router-dom";

import bootstrap from 'bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import './sass/app.scss'; 
import 'animate.css/animate.min.css';

import Header from './views/layout/header';
import Footer from './views/layout/footer';
import Home from './views/home';
import Identidad from './views/identidad';
import Preescolar from './views/preescolar';
import Primaria from './views/primaria';
import Secundaria from './views/secundaria';
import Preparatoria from './views/preparatoria';
import Instalaciones from './views/instalaciones';
import Contacto from './views/contacto';
import Utils from './utils/utils';

export default function Master() {

  useEffect(() => {
    Utils();
  }, []); // <-- empty array means 'run once'

  return (
    <Router>
      <>
        <Header />
        <Switch>
          <Route exact path="/">
            <Home />
          </Route>
          <Route exact path="/identidad">
            <Identidad />
          </Route>
          <Route exact path="/preescolar">
            <Preescolar />
          </Route>
          <Route exact path="/primaria">
            <Primaria />
          </Route>
          <Route exact path="/secundaria">
            <Secundaria />
          </Route>
          <Route exact path="/preparatoria">
            <Preparatoria />
          </Route>
          <Route exact path="/instalaciones">
            <Instalaciones />
          </Route>
          <Route exact path="/contacto">
            <Contacto />
          </Route>
        </Switch>
        <Footer />
      </>
    </Router>
  );
}