import React, { useRef } from 'react';
import { Link } from "react-router-dom";

import 'react-glidejs/dist/index.css';

export default function Identidad() {

    return (
        <main className="page">
            <section className="page-cover animate" data-animation="fadeIn" data-animationdelay="0">
                <div className="page-cover--background" style={{backgroundImage : "url('images/img-cover-identidad.jpg')" }}></div>
                <div className="page-cover--caption page-cover--caption--spaced animate" data-animation="fadeIn" data-animationdelay="1800">
                    <img src="images/txt-nuestra-identidad.svg" alt="" />
                </div>
                <div className="page-cover--frame">
                    <span className="deco deco--leftTopTriangle animate" data-animation="fadeInLeft" data-animationdelay="200"></span>
                    <span className="deco deco--aniversary animate" data-animation="fadeIn" data-animationdelay="400"></span>
                    <span className="deco deco--circle animate" data-animation="fadeInUp" data-animationdelay="600"><span className="lg-maristas lg-maristas--white"></span></span>
                    <span className="deco deco--rightBottomBubble animate" data-animation="fadeInRight" data-animationdelay="800"></span>
                </div>
                <ul className="page-cover--nav">
                    <li className="page-cover--nav--item active"><Link to="/">Preescolar</Link></li>
                    <li className="page-cover--nav--item"><Link to="/">Primaria</Link></li>
                    <li className="page-cover--nav--item"><Link to="/">Secundaria</Link></li>
                    <li className="page-cover--nav--item"><Link to="/">Preparatoria</Link></li>
                </ul>
            </section>
            <section>
                <div className="half-card">
                    <div className="half-card--description half-card--description--extended">
                        <div className="half-card--content">
                            <div className="tabs-menu tabs-menu--extended">                        
                                <ul className="nav nav-tabs" role="tablist">
                                    <li className="nav-link active" id="nav-preescolar-tab" data-bs-toggle="tab" data-bs-target="#tab-preescolar" role="tab" aria-controls="nav-preescolar-tab" aria-selected="true">Quienes somos</li>
                                    <li className="nav-link" id="nav-primaria-tab" data-bs-toggle="tab" data-bs-target="#tab-primaria" role="tab" aria-controls="nav-primaria-tab" aria-selected="false">Formación Marista</li>
                                    <li className="nav-link" id="nav-secundaria-tab" data-bs-toggle="tab" data-bs-target="#tab-secundaria" role="tab" aria-controls="nav-secundaria-tab" aria-selected="false">Marcelino Champagnat</li>
                                    <li className="nav-link" id="nav-preparatoria-tab" data-bs-toggle="tab" data-bs-target="#tab-preparatoria" role="tab" aria-controls="nav-preparatoria-tab" aria-selected="false">Red de colegios Maristas</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div className="half-card--thumb" style={{ backgroundImage : "url('images/img-primaria.jpg')" }}>
                    </div>
                </div>
                <div className="row align-items-center">
                    <div className="intro-areas--identity col-md-6">
                        <p className="quote">
                            Comprometidos con <br />
                            <b><span className="text-secondary">el futuro</span></b><br />
                            de cada alumno
                        </p>
                    </div>
                    <div className="intro-areas--identity-description col-md-6">
                        <p>
                            El Instituto Valladolid está basado en la formación integral de la persona humana, entendida como la capacidad de armonizar: fe, vida y cultura, mediante la educación al estilo de Marcelino Champagnat.
                        </p>
                        <p>
                            El Instituto Valladolid tiene presencia en la ciudad de Morelia por más de 77 años y en México, desde el año 1899. Años en los que se ve reflejado experiencia, identidad educativa y excelencia en cada una de sus clases y materias.
                        </p>
                    </div>
                </div>
                <div className="full-content row">
                    <div className="col-12">
                        <div className="video">
                            <iframe className="video--element" src="https://www.youtube.com/embed/mHxHSBqbhg4" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                        </div>
                    </div>
                </div>
                <div className="philosophy">
                    <span className="deco deco--leftTopBubble"></span>
                    <span className="deco deco--mask deco--rightBottomDonut"></span>
                    <div className="row">
                        <div className="col-md-4">
                            <h3 className="text-color4">Misión</h3>
                            <p>
                                Nuestra misión es la formación integral de la persona humana, entendida como la capacidad de armonizar fe, vida y cultura mediante la educación al estilo de Champagnat.
                            </p>
                        </div>
                        <div className="col-md-4">
                            <h3 className="text-color3">Visión</h3>
                            <p>
                                El instituto Valladolid, en el 2021, será reconocida por:
                                <ul>
                                    <li>Su participación en la difusión del conocimiento y la cultura.</li>
                                    <li>La formación de alumnos en la excelencia académica.</li>
                                    <li>Ser una comunidad sólida y trascendente.</li>
                                    <li>Su liderazgo en acciones a favor del ser humano.</li>
                                </ul>
                            </p>
                        </div>
                        <div className="col-md-4">
                            <h3 className="text-color2">Compromiso</h3>
                            <p>
                                El Instituto Valladolid está comprometido a realizar su labor de identidad educativa a través de la comunidad educativa, es decir, Padres de Familia, Maestros y el mismo alumno, a propiciar el desarrollo armónico e integral de las facultades y posibilidades de cada persona, mediante una relación sana y significativa consigo mismo, con los demás, con la naturaleza y con Dios.
                            </p>
                        </div>
                    </div>
                </div>
            </section>
            
        </main>
    );
}