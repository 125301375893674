import React, { useRef } from 'react';
import { Link } from "react-router-dom";

import 'react-glidejs/dist/index.css';

export default function Instalaciones() {

    return (
        <main className="page">
            <section className="page-cover">
                <div className="page-cover--background" style={{backgroundImage : "url('images/img-cover-instalaciones.jpg')" }}></div>
                <div className="page-cover--caption page-cover--caption--spaced">
                    <img src="images/txt-instalaciones.svg" alt="" />
                </div>
                <div className="page-cover--frame">
                    <span className="deco deco--leftTopTriangle"></span>
                    <span className="deco deco--aniversary"></span>
                    <span className="deco deco--circle"><span className="lg-maristas lg-maristas--white"></span></span>
                    <span className="deco deco--rightBottomBubble"></span>
                </div>
                <ul className="page-cover--nav">
                    <li className="page-cover--nav--item active"><Link to="/">Preescolar</Link></li>
                    <li className="page-cover--nav--item"><Link to="/">Primaria</Link></li>
                    <li className="page-cover--nav--item"><Link to="/">Secundaria</Link></li>
                    <li className="page-cover--nav--item"><Link to="/">Preparatoria</Link></li>
                </ul>
            </section>
            <section>
                <div className="full-content row align-items-center">
                    <div className="full-content--intro col-md-6">
                        <p className="quote">
                            Conoce nuestras <br />
                            <b><span className="text-secondary">instalaciones</span></b>
                        </p>
                        <p>
                            El Instituto Valladolid cuenta con cinco secciones, las cuales están distribuidas en diferentes puntos de la ciudad de Morelia. Entre las instalaciones con las que se cuentan están: centros de cómputo, salas audio visuales, un gimnasio perfectamente adecuado; diversas canchas, cada una cuenta con capillas; áreas verdes, alberca, auditorios, entre otros.
                        </p>
                    </div>
                    <div className="icons-mosaic col-md-6">
                        <div className="row">
                            <div className="col-4 col-md-2">
                                <div className="icon-card">
                                    <span className="icon icon--some"></span>
                                    <div className="icon-card--desc">Biblioteca</div>
                                </div>
                            </div>
                            <div className="col-4 col-md-2">
                                <div className="icon-card">
                                    <span className="icon icon--some"></span>
                                    <div className="icon-card--desc">Salas audiovisuales</div>
                                </div>
                            </div>
                            <div className="col-4 col-md-2">
                                <div className="icon-card">
                                    <span className="icon icon--some"></span>
                                    <div className="icon-card--desc">Aulas para idiomas</div>
                                </div>
                            </div>
                            <div className="col-4 col-md-2">
                                <div className="icon-card">
                                    <span className="icon icon--some"></span>
                                    <div className="icon-card--desc">Soluciones para talleres</div>
                                </div>
                            </div>
                            <div className="col-4 col-md-2">
                                <div className="icon-card">
                                    <span className="icon icon--some"></span>
                                    <div className="icon-card--desc">Capillas</div>
                                </div>
                            </div>
                            <div className="col-4 col-md-2">
                                <div className="icon-card">
                                    <span className="icon icon--some"></span>
                                    <div className="icon-card--desc">Patios</div>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-4 col-md-2">
                                <div className="icon-card">
                                    <span className="icon icon--some"></span>
                                    <div className="icon-card--desc">Auditorios</div>
                                </div>
                            </div>
                            <div className="col-4 col-md-2">
                                <div className="icon-card">
                                    <span className="icon icon--some"></span>
                                    <div className="icon-card--desc">Cafeterías</div>
                                </div>
                            </div>
                            <div className="col-4 col-md-2">
                                <div className="icon-card">
                                    <span className="icon icon--some"></span>
                                    <div className="icon-card--desc">Gimnasio</div>
                                </div>
                            </div>
                            <div className="col-4 col-md-2">
                                <div className="icon-card">
                                    <span className="icon icon--some"></span>
                                    <div className="icon-card--desc">Salón de música</div>
                                </div>
                            </div>
                            <div className="col-4 col-md-2">
                                <div className="icon-card">
                                    <span className="icon icon--some"></span>
                                    <div className="icon-card--desc">Salón de danza</div>
                                </div>
                            </div>
                            <div className="col-4 col-md-2">
                                <div className="icon-card">
                                    <span className="icon icon--some"></span>
                                    <div className="icon-card--desc">Salón de pintura</div>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-4 col-md-2">
                                <div className="icon-card">
                                    <span className="icon icon--some"></span>
                                    <div className="icon-card--desc">Salón de usos múltiples</div>
                                </div>
                            </div>
                            <div className="col-4 col-md-2">
                                <div className="icon-card">
                                    <span className="icon icon--some"></span>
                                    <div className="icon-card--desc">Canchas de básquetbol</div>
                                </div>
                            </div>
                            <div className="col-4 col-md-2">
                                <div className="icon-card">
                                    <span className="icon icon--some"></span>
                                    <div className="icon-card--desc">Canchas de volleybol</div>
                                </div>
                            </div>
                            <div className="col-4 col-md-2">
                                <div className="icon-card">
                                    <span className="icon icon--some"></span>
                                    <div className="icon-card--desc">Canchas de futbol soccer</div>
                                </div>
                            </div>
                            <div className="col-4 col-md-2">
                                <div className="icon-card">
                                    <span className="icon icon--some"></span>
                                    <div className="icon-card--desc">Canchas de futbol rápido</div>
                                </div>
                            </div>
                            <div className="col-4 col-md-2">
                                <div className="icon-card">
                                    <span className="icon icon--some"></span>
                                    <div className="icon-card--desc">Alberca</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section>
                <div className="text-center">
                    <h2>Recorridos <span class="text-secondary">360</span></h2>
                </div>
                <div className="full-content row align-items-center">
                    <div className="col-md-6">
                        <div className="tab-content" id="myTabContent">
                            <div className="tab-pane fade show active" id="tab-preescolar" role="tabpanel" aria-labelledby="preescolar-tab">
                                <div className="video">
                                    <iframe className="video--element" src="https://my.matterport.com/show/?m=dsKzQa2e7Kb" frameborder="0" allowfullscreen />
                                </div>
                            </div>
                            <div className="tab-pane fade" id="tab-primaria" role="tabpanel" aria-labelledby="primaria-tab">
                                <div className="video">
                                    <iframe className="video--element" src="https://my.matterport.com/show/?m=MCwpAN9ry4F" frameborder="0" allowfullscreen />
                                </div>
                            </div>
                            <div className="tab-pane fade" id="tab-secundaria" role="tabpanel" aria-labelledby="secundaria-tab">
                                <div className="video">
                                    <iframe className="video--element" src="https://my.matterport.com/show/?m=GgVT35rUKtv" frameborder="0" allowfullscreen />
                                </div>
                            </div>
                            <div className="tab-pane fade" id="tab-preparatoria" role="tabpanel" aria-labelledby="preparatoria-tab">
                                <div className="video">
                                    <iframe className="video--element" src="https://my.matterport.com/show/?m=dPSwm8Tsm4g" frameborder="0" allowfullscreen />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-6">
                        <div className="tabs-menu">                        
                                <ul className="nav nav-tabs" role="tablist">
                                    <li className="nav-link active" id="nav-preescolar-tab" data-bs-toggle="tab" data-bs-target="#tab-preescolar" role="tab" aria-controls="nav-preescolar-tab" aria-selected="true">Preescolar</li>
                                    <li className="nav-link" id="nav-primaria-tab" data-bs-toggle="tab" data-bs-target="#tab-primaria" role="tab" aria-controls="nav-primaria-tab" aria-selected="false">Primaria</li>
                                    <li className="nav-link" id="nav-secundaria-tab" data-bs-toggle="tab" data-bs-target="#tab-secundaria" role="tab" aria-controls="nav-secundaria-tab" aria-selected="false">Secundaria</li>
                                    <li className="nav-link" id="nav-preparatoria-tab" data-bs-toggle="tab" data-bs-target="#tab-preparatoria" role="tab" aria-controls="nav-preparatoria-tab" aria-selected="false">Preparatoria</li>
                                </ul>
                            </div>
                    </div>
                </div>
            </section>
            
        </main>
    );
}