import React, { useRef } from 'react';
import { Link } from "react-router-dom";
import Glide from 'react-glidejs';

import 'react-glidejs/dist/index.css';

export default function Home() {
    const gliderRef = useRef(null);

    return (
        <main className="page">
            <section className="page-cover animate" data-animation="fadeIn" data-animationdelay="0">
                <div className="page-cover--background" style={{backgroundImage : "url('images/img-cover-1.jpg')" }}></div>
                <div className="page-cover--caption animate" data-animation="fadeIn" data-animationdelay="1800">
                    <span className="lg-aniversary"></span><br />
                    <img src="images/txt-educamos-con-amor.svg" alt="" />
                </div>
                <div className="page-cover--frame">
                    <span className="deco deco--leftTopTriangle animate" data-animation="fadeInLeft" data-animationdelay="300"></span>
                    <span className="deco deco--aniversary animate" data-animation="fadeIn" data-animationdelay="600"></span>
                    <span className="deco deco--circle animate" data-animation="fadeInUp" data-animationdelay="900"><span className="lg-maristas lg-maristas--white"></span></span>
                    <span className="deco deco--rightBottomBubble animate" data-animation="fadeInRight" data-animationdelay="1200"></span>
                </div>
                <ul className="page-cover--nav">
                    <li className="page-cover--nav--item active"><Link to="/">Preescolar</Link></li>
                    <li className="page-cover--nav--item"><Link to="/">Primaria</Link></li>
                    <li className="page-cover--nav--item"><Link to="/">Secundaria</Link></li>
                    <li className="page-cover--nav--item"><Link to="/">Preparatoria</Link></li>
                </ul>
            </section>
            <section className="half-card">
                <div className="half-card--thumb" style={{ backgroundImage : "url('images/img-primaria.jpg')" }}>
                    <span className="deco deco--mask deco--rightBottomDonut animate" data-animation="fadeIn" data-animationdelay="300"></span>
                </div>
                <div className="half-card--description animate" data-animation="fadeInRight" data-animationdelay="600">
                    <div className="half-card--content">
                        <h2 className="section-subtitle">Educando <b>Con Valor</b></h2>
                        <p>
                            El Instituto Valladolid es una institución educativa fundada por Hermanos Maristas que en unión con personas laicas están comprometidos con el futuro de los alumnos.
                        </p>
                        <Link to="/identidad" className="btn btn--primary">Conoce nuestra identidad</Link>
                    </div>
                </div>
            </section>
            <section className="intro-areas">
                <span className="deco deco--leftTopBubble"></span>
                <div className="row align-items-center">
                    <div className="intro-areas--identity col-md-6 animate" data-animation="fadeIn" data-animationdelay="600">
                        <span className="lg-maristas"></span>
                    </div>
                    <div className="intro-areas--identity-description col-md-6 animate" data-animation="fadeInLeft" data-animationdelay="200">
                        <p>
                            El Instituto Valladolid es una institución educativa fundada por Hermanos Maristas que en unión con personas laicas están comprometidos con el futuro de los alumnos.
                        </p>
                    </div>
                </div>
                <div className="row">
                    <div className="tabs-menu col-md-6">
                        <h2 className="section-subtitle">Oferta <b>educativa</b></h2>
                        
                        <ul className="nav nav-tabs" role="tablist">
                            <li className="nav-link active animate" data-animation="fadeInLeft" data-animationdelay="200" id="nav-preescolar-tab" data-bs-toggle="tab" data-bs-target="#tab-preescolar" role="tab" aria-controls="nav-preescolar-tab" aria-selected="true">Preescolar</li>
                            <li className="nav-link animate" data-animation="fadeInLeft" data-animationdelay="400" id="nav-primaria-tab" data-bs-toggle="tab" data-bs-target="#tab-primaria" role="tab" aria-controls="nav-primaria-tab" aria-selected="false">Primaria</li>
                            <li className="nav-link animate" data-animation="fadeInLeft" data-animationdelay="600" id="nav-secundaria-tab" data-bs-toggle="tab" data-bs-target="#tab-secundaria" role="tab" aria-controls="nav-secundaria-tab" aria-selected="false">Secundaria</li>
                            <li className="nav-link animate" data-animation="fadeInLeft" data-animationdelay="800" id="nav-preparatoria-tab" data-bs-toggle="tab" data-bs-target="#tab-preparatoria" role="tab" aria-controls="nav-preparatoria-tab" aria-selected="false">Preparatoria</li>
                        </ul>
                    </div>
                    <div className="col-md-6">
                        <div className="intro-areas--card">
                            <div className="tab-content" id="myTabContent">
                                <div className="tab-pane fade show active" id="tab-preescolar" role="tabpanel" aria-labelledby="preescolar-tab">
                                    <div className="card card--preescolar">
                                        <div className="card--thumb" style={{ backgroundImage : "url('images/img-preescolar-nina.jpg')" }}></div>
                                        <div className="card--description">
                                            <span className="deco deco--rightBottomDonut"></span>
                                            <div>
                                                <h3>Preescolar</h3>
                                                <p>
                                                    El Instituto Valladolid es una institución educativa fundada por Hermanos Maristas que en unión con personas laicas están comprometidos con el futuro de los alumnos.
                                                </p>
                                                <Link to="/primaria" className="btn btn-primary">Ver más</Link>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="tab-pane fade" id="tab-primaria" role="tabpanel" aria-labelledby="primaria-tab">
                                    <div className="card card--primaria">
                                        <div className="card--thumb" style={{ backgroundImage : "url('images/img-preescolar-nina.jpg')" }}></div>
                                        <div className="card--description">
                                            <span className="deco deco--rightBottomDonut"></span>
                                            <div>
                                                <h3>Primaria</h3>
                                                <p>
                                                    Lorem, ipsum dolor sit amet consectetur adipisicing elit. Illum aut nihil inventore aspernatur iure blanditiis nemo? Eius minima quaerat architecto nemo veritatis doloribus rem, similique mollitia itaque hic magni dolorum?
                                                </p>
                                                <Link to="/primaria" className="btn btn-primary">Ver más</Link>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="tab-pane fade" id="tab-secundaria" role="tabpanel" aria-labelledby="secundaria-tab">
                                    <div className="card card--secundaria">
                                        <div className="card--thumb" style={{ backgroundImage : "url('images/img-preescolar-nina.jpg')" }}></div>
                                        <div className="card--description">
                                            <span className="deco deco--rightBottomDonut"></span>
                                            <div>
                                                <h3>Secundaria</h3>
                                                <p>
                                                    Lorem, ipsum dolor sit amet consectetur adipisicing elit. Illum aut nihil inventore aspernatur iure blanditiis nemo? Eius minima quaerat architecto nemo veritatis doloribus rem, similique mollitia itaque hic magni dolorum?
                                                </p>
                                                <Link to="/primaria" className="btn btn-primary">Ver más</Link>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="tab-pane fade" id="tab-preparatoria" role="tabpanel" aria-labelledby="preparatoria-tab">
                                    <div className="card card--preparatoria">
                                        <div className="card--thumb" style={{ backgroundImage : "url('images/img-preescolar-nina.jpg')" }}></div>
                                        <div className="card--description">
                                            <span className="deco deco--rightBottomDonut"></span>
                                            <div>
                                                <h3>Preparatoria</h3>
                                                <p>
                                                    Lorem, ipsum dolor sit amet consectetur adipisicing elit. Illum aut nihil inventore aspernatur iure blanditiis nemo? Eius minima quaerat architecto nemo veritatis doloribus rem, similique mollitia itaque hic magni dolorum?
                                                </p>
                                                <Link to="/primaria" className="btn btn-primary">Ver más</Link>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>                            
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="partnerships-list">
                        <h2 className="section-subtitle text-center"><b>convenios</b></h2>
                        <Glide
                            ref={gliderRef}
                            throttle={0}
                            type="slider"
                            customSlideAnimation={{
                                timeout: 500,
                                classNames: 'fade',
                            }}
                            peek={{
                                before: 500,
                                after: 500,
                            }}
                            perView={2}
                            startAt={3}
                            slideClassName="slider__frame"
                            focusAt="center"
                        >
                            <React.Fragment>
                                <img src="images/partnerships/comce.svg" alt="" />
                            </React.Fragment>
                            <React.Fragment>
                                <img src="images/partnerships/comillas.svg" alt="" />
                            </React.Fragment>
                            <React.Fragment>
                                <img src="images/partnerships/coparmex.svg" alt="" />
                            </React.Fragment>
                            <React.Fragment>
                                <img src="images/partnerships/cruz_roja.svg" alt="" />
                            </React.Fragment>
                            <React.Fragment>
                                <img src="images/partnerships/fundacion_teleton.svg" alt="" />
                            </React.Fragment>
                            <React.Fragment>
                                <img src="images/partnerships/goose_and_hopper.svg" alt="" />
                            </React.Fragment>
                            <React.Fragment>
                                <img src="images/partnerships/ignitemedia.svg" alt="" />
                            </React.Fragment>
                            <React.Fragment>
                                <img src="images/partnerships/mizani.svg" alt="" />
                            </React.Fragment>
                            <React.Fragment>
                                <img src="images/partnerships/secretaria_de_la_juventud_muchiacana.svg" alt="" />
                            </React.Fragment>
                            <React.Fragment>
                                <img src="images/partnerships/sinestesia.svg" alt="" />
                            </React.Fragment>
                        </Glide>
                    </div>
                </div>
                <div className="deco deco--leftTopBubble"></div>
            </section>
            <section className="campaing animate" data-animation="fadeInLeft" data-animationdelay="200">
                <img src="images/campaign/banner_universidad.jpg" alt="" />
            </section>
            <section className="news-feed">
                <h2 className="section-subtitle--primary text-center">Síguenos en <b><span className="icon icon--facebook"></span> InstitutoValladolidMorelia</b></h2>
                <ul className="row">
                    <li className="col-md-4">
                        <div className="news-card animate" data-animation="fadeInUp" data-animationdelay="200">
                            <div className="news-card--header">
                                <span className="post--photo" style={{ backgroundImage : "url(http://placehold.it/120x120)" }}></span>
                                <div className="post--profile-name">Instituto Valladolid</div>
                                <div className="post--date">Mayo 23, 2021</div>
                                <div className="post--icon"><span className="icon icon--facebook"></span></div>
                            </div>
                            <div className="news-card--content">
                                <div className="post--thumb" style={{ backgroundImage : "url(http://placehold.it/1200x800)" }}></div>
                                <div className="post--content">
                                    <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Reprehenderit ea eius alias dignissimos sit laboriosam, iure doloremque neque eaque officia doloribus deserunt reiciendis veritatis, ipsam, rem magnam nostrum itaque sapiente.</p>
                                </div>
                            </div>
                        </div>
                    </li>
                    <li className="col-md-4">
                        <div className="news-card animate" data-animation="fadeInUp" data-animationdelay="400">
                            <div className="news-card--header">
                                <span className="post--photo" style={{ backgroundImage : "url(http://placehold.it/120x120)" }}></span>
                                <div className="post--profile-name">Instituto Valladolid</div>
                                <div className="post--date">Mayo 23, 2021</div>
                                <div className="post--icon"><span className="icon icon--facebook"></span></div>
                            </div>
                            <div className="news-card--content">
                                <div className="post--thumb" style={{ backgroundImage : "url(http://placehold.it/1200x800)" }}></div>
                                <div className="post--content">
                                    <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Reprehenderit ea eius alias dignissimos sit laboriosam, iure doloremque neque eaque officia doloribus deserunt reiciendis veritatis, ipsam, rem magnam nostrum itaque sapiente.</p>
                                </div>
                            </div>
                        </div>
                    </li>
                    <li className="col-md-4">
                        <div className="news-card animate" data-animation="fadeInUp" data-animationdelay="600">
                            <div className="news-card--header">
                                <span className="post--photo" style={{ backgroundImage : "url(http://placehold.it/120x120)" }}></span>
                                <div className="post--profile-name">Instituto Valladolid</div>
                                <div className="post--date">Mayo 23, 2021</div>
                                <div className="post--icon"><span className="icon icon--facebook"></span></div>
                            </div>
                            <div className="news-card--content">
                                <div className="post--thumb" style={{ backgroundImage : "url(http://placehold.it/1200x800)" }}></div>
                                <div className="post--content">
                                    <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Reprehenderit ea eius alias dignissimos sit laboriosam, iure doloremque neque eaque officia doloribus deserunt reiciendis veritatis, ipsam, rem magnam nostrum itaque sapiente.</p>
                                </div>
                            </div>
                        </div>
                    </li>
                </ul>
            </section>
        </main>
    );
}