import React, { useEffect } from "react";
import { Link, useLocation } from "react-router-dom";

const Menu = ({ open, setOpen }) => {
    const location = useLocation();

    useEffect(() => {
        //console.log('Location changed', location);
        setOpen(false);
    }, [location]);

    return (
        <nav className={ open ? 'main-navbar active' : 'main-navbar'}>
            <div className="main-navbar--links">
                <ul>
                    <li><Link to="/">Inicio</Link></li>
                    <li><Link to="/identidad">Identidad</Link></li>
                    <li><Link to="/instalaciones">Instalaciones</Link></li>
                    <li><Link to="/">Oferta Educativa</Link>
                        <ul>
                            <li><Link to="/preescolar">Preescolar</Link></li>
                            <li><Link to="/primaria">Primaria</Link></li>
                            <li><Link to="/secundaria">Secundaria</Link></li>
                            <li><Link to="/preparatoria">Preparatoria</Link></li>
                        </ul>
                    </li>
                    <li><Link to="/contacto">Contacto</Link></li>
                </ul>
                <div className="main-navbar--footer">
                    <Link to="/"><span className="lg-vallaID"></span></Link><br />
                    <Link to="/">Programa de Becas</Link>
                </div>
            </div>
            <div className="main-navbar--identity">
                <span className="lg-company lg-company--white"></span>
                <div className="text-center">
                    <span className="lg-aniversary"></span>
                </div>
            </div>
        </nav>
    )
}

const Burger = ({ open, setOpen }) => {
    return (
        <button className={'btn btn--menu' + (open ? ' active' : '')} open={open} onClick={() => setOpen(!open)}>
            <span />
            Menú
        </button>
    )
}


export default function Header() {
    
    const [open, setOpen] = React.useState(false);
    const node = React.useRef();

    return (
        <header className="main-header">
            <div className="row">
                <div ref={node} className="main-header--menu col col-3">
                    <Burger open={open} setOpen={setOpen} />
                </div>
                <div className="main-header--brand col col-md-6">
                    <Link to="/"><span className="lg-company"></span></Link>
                </div>
                <div className="main-header--quick-links col col-md-3">
                    <img src="images/img-ser_valla.svg" alt="" />
                </div>
            </div>
            <Menu open={open} setOpen={setOpen} />
        </header>
    )
}