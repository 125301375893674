import React from 'react';
import { Link } from "react-router-dom";

export default function Preescolar() {

    return (
        <main className="page">
            <section className="page-cover">
                <div className="page-cover--background" style={{backgroundImage : "url('images/img-cover-preescolar.jpg')" }}></div>
                <div className="page-cover--caption page-cover--caption--spaced">
                    <img src="images/txt-preescolar.svg" alt="" />
                </div>
                <div className="page-cover--frame">
                    <span className="deco deco--leftTopTriangle"></span>
                    <span className="deco deco--aniversary"></span>
                    <span className="deco deco--circle"><span className="lg-maristas lg-maristas--white"></span></span>
                    <span className="deco deco--rightBottomBubble"></span>
                </div>
                <ul className="page-cover--nav">
                    <li className="page-cover--nav--item active"><Link to="/">Preescolar</Link></li>
                    <li className="page-cover--nav--item"><Link to="/">Primaria</Link></li>
                    <li className="page-cover--nav--item"><Link to="/">Secundaria</Link></li>
                    <li className="page-cover--nav--item"><Link to="/">Preparatoria</Link></li>
                </ul>
            </section>
            <section className="half-card">
                <div className="half-card--description">
                    <div className="half-card--content">
                        <h2 className="section-subtitle section-subtitle--inverted">
                            Nos ocupamos de la<br /> <b>educación integral de su hijo</b>
                        </h2>
                        <p>
                            <b>Les ofrecemos los siguientes servicios:</b>
                        </p>
                        <ul>
                            <li>Departamento Psicopedagógico</li>
                            <li>Taller para padres</li>
                            <li>Clases de inglés</li>
                            <li>Estimulación física y motriz</li>
                            <li>Actividades culturales y deportivas vespertinas sin costo</li>
                            <li>Servicio de comedor</li>
                            <li>Atención médica</li>
                            <li>Programa de becas</li>
                            <li>Extensión de horario</li>
                        </ul>
                    </div>
                </div>
                <div className="half-card--thumb" style={{ backgroundImage : "url('images/img-preescolar-intro.jpg')" }}>
                    <span className="deco deco--mask deco--circle"></span>
                </div>
            </section>
            <div className="full-content row">
                <div className="col-12">
                    <div className="video">
                        <iframe className="video--element" title="Recorrido virtual" src="https://my.matterport.com/show/?m=dsKzQa2e7Kb" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
                    </div>
                </div>
            </div>
            <section className="half-card">
                <div className="half-card--description">
                    <div className="half-card--content">
                        <h2 className="section-subtitle--emphasis">Periodo de inscripción al ciclo escolar <br />2020-2021</h2>
                        <p>
                            Horario de atención de 9:00 a 13:00 hrs.<br />
                            Horario de clases de 7:40 a 13:45 hrs.
                        </p>
                    </div>
                </div>
                <div className="half-card--thumb">
                    <div>
                        <h3>Descargas</h3>
                        <ul className="downloads-list">
                            <li><Link to="/"><span className="icon icon-file"></span>Descargar Ficha de Admisión - Control Escolar</Link></li>
                            <li><Link to="/"><span className="icon icon-file"></span>Descargar Ficha de Proceso de Admisión 21 - 22</Link></li>
                            <li><Link to="/"><span className="icon icon-file"></span>Aviso de privacidad</Link></li>
                        </ul>
                        <span className="deco deco--mask deco--rightBottomDonut"></span>
                    </div>
                </div>
            </section>
            
        </main>
    );
}