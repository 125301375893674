let animation_elements = [];
function check_if_in_view(){
    let window_height = window.innerHeight;
    let window_top_position = window.scrollY;
    let window_bottom_position = (window_top_position + window_height);

    animation_elements.forEach(element => {
        let element_height = element.offsetHeight;
        let element_rect = element.getBoundingClientRect();
        const scrollTop = window.pageYOffset || document.documentElement.scrollTop;
        let element_top_position = element_rect.top + scrollTop;
        let element_bottom_position = (element_top_position + element_height);

        //check to see if this current container is within viewport
        if ((element_bottom_position >= window_top_position) &&
            (element_top_position <= window_bottom_position)) {
            let delay = (element.dataset.animationdelay !== 'undefined') ? element.dataset.animationdelay : 0;
            setTimeout(function () {
                element.classList.add('animate__animated','animate__'+element.dataset.animation);
                element.removeAttribute('style');
            }, delay);
        } else {
            if(element.classList.contains('animate-reverse'))
                element.classList.remove('animate__animated','animate__'+element.dataset.animation);
        }
    })
}

function init(){
    document.addEventListener('DOMContentLoaded', function(){
        /*
        * Animation while scrolling
        */
        console.log('Starting utils...')

        animation_elements = document.querySelectorAll('.animate');
        animation_elements.forEach(element => element.style.opacity = 0 );
        check_if_in_view();
        window.addEventListener('scroll',check_if_in_view);
        window.addEventListener('resize',check_if_in_view);
    });
}

export { init as default };