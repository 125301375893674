import { Link } from "react-router-dom";
import GoogleMap from './map';

export default function Footer() {
    return (
        <footer className="main-footer">
            <div className="interactive-map">
                <ul className="interactive-map--nav">
                    <li className="selected animate" data-animation="fadeInUp" data-animationdelay="200">Preescolar</li>
                    <li className=" animate" data-animation="fadeInUp" data-animationdelay="400">Primaria</li>
                    <li className=" animate" data-animation="fadeInUp" data-animationdelay="600">Secundaria</li>
                    <li className=" animate" data-animation="fadeInUp" data-animationdelay="800">Preparatoria</li>
                </ul>
                <div className="interactive-map--explore">
                    <GoogleMap />
                </div>
                <div className="interactive-map--directory">
                    <ul>
                        <li><span className="icon icon--white icon--location"></span>Río Amatlán 125, Cuauhtémoc.</li>
                        <li><span className="icon icon--white icon--phone"></span>(443) 313 2098</li>
                        <li><span className="icon icon--white icon--microsite"></span>Micrositio</li>
                        <li><span className="icon icon--white icon--360"></span>Recorrido virtual</li>
                        <li><span className="icon icon--white icon--location"></span>Ver en Google Maps</li>
                    </ul>
                </div>
            </div>
            <div className="main-footer--credits">
                <div className="row">
                    <div className="col-md-4">
                        <span className="lg-aniversary"></span>
                    </div>
                    <div className="col-md-4">
                        <div className="d-flex align-items-center justify-content-between">
                            <span className="lg-maristas lg-maristas--white"></span>
                            <span className="lg-valla lg-valla--white"></span>
                            <span className="lg-champagnat lg-champagnat--white"></span>
                        </div>
                        <div className="main-footer--legals">
                            Instituto Valladolid - Morelia &reg; Todos los derechos reservados. Desarrollo del sitio web <a href="#">RENDERINC</a>
                        </div>
                    </div>
                    <div className="col-md-4">
                        <img src="/images/img-ser_valla.svg" alt="" />
                    </div>
                </div>
            </div>
        </footer>
    )
}