import React, { useRef } from 'react';
import { Link } from "react-router-dom";

import 'react-glidejs/dist/index.css';

export default function Contacto() {

    return (
        <main className="page">
            <section className="page-cover">
                <div className="page-cover--background" style={{backgroundImage : "url('images/img-cover-contacto.jpg')" }}></div>
                <div className="page-cover--caption page-cover--caption--spaced">
                    <img src="images/txt-contacto.svg" alt="" />
                </div>
                <div className="page-cover--frame">
                    <span className="deco deco--leftTopTriangle"></span>
                    <span className="deco deco--aniversary"></span>
                    <span className="deco deco--circle"><span className="lg-maristas lg-maristas--white"></span></span>
                    <span className="deco deco--rightBottomBubble"></span>
                </div>
                <ul className="page-cover--nav">
                    <li className="page-cover--nav--item active"><Link to="/">Preescolar</Link></li>
                    <li className="page-cover--nav--item"><Link to="/">Primaria</Link></li>
                    <li className="page-cover--nav--item"><Link to="/">Secundaria</Link></li>
                    <li className="page-cover--nav--item"><Link to="/">Preparatoria</Link></li>
                </ul>
            </section>
            
            <section>
                <div className="full-content row align-items-center">
                    <div className="full-content--intro col-md-6">
                        <p className="quote">
                            Comunícate con <br />
                            <b><span className="text-secondary">nosotros</span></b>
                        </p>
                    </div>
                    <div className="icons-mosaic col-md-6">
                        <p>
                            Lorem ipsum dolor sit amet consectetur adipisicing elit. Inventore quaerat assumenda illum, explicabo quisquam repellendus, odio non dolores nemo sapiente ad voluptatibus dolor placeat reiciendis rem. Quis quo eos nemo.
                        </p>
                        <button className="btn btn-primary"><span className="icon icon-chat"></span> Iniciar chatbot</button>
                    </div>
                </div>
            </section>
            
        </main>
    );
}